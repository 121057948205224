import React, { useState, useEffect, useCallback } from 'react';
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import Map from 'examples/Maps';
import StoryCard from 'examples/StoryCard';

import NotificationItem from "examples/Items/NotificationItem";
/* eslint-disable react/prop-types */

import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MenuItem from "@mui/material/MenuItem";

import { TbPlayCardStarFilled, TbCardsFilled } from "react-icons/tb";
import { PiMapPinAreaFill } from "react-icons/pi";
import { faLocationArrow, faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


//import TinderCard from 'react-tinder-card';
import Swing from 'react-swing';
import { Direction as SwingDirection } from 'swing';
import { useRef } from 'react';

import Stories from 'stories-react';


function Page() {
    const username = useSelector(state => state.auth.username);
    const { t } = useTranslation();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [dataFollow, setDataFollow] = useState([]);
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedMode, setSelectedMode] = useState(0);

    const [swipeMessage, setSwipeMessage] = useState(""); // Kaydırma mesajını tutacak state


    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const handleMenuSelection = async (menuName) => {

        setSelectedMode(menuName)
        setOpenMenu(false);
    };

    const stackRef = useRef(null);

    const swingConfig = {
        allowedDirections: [SwingDirection.LEFT, SwingDirection.RIGHT],
        throwOutConfidence: () => 1, // En ufak xOffset bile fırlatma demek
        isThrowingOut: () => true,   // Her durumda kartın fırlatılmış sayılmasını sağlar
        minThrowOutDistance: 500,
        maxThrowOutDistance: 500,
    };



    const stories = ["https://i.pinimg.com/736x/1e/c9/ff/1ec9ff8b23fd72e3136141e0d513dbac.jpg",
        "https://api.kashik.net//story_covers/image_7.jpg",
        "https://api.kashik.net//story_covers/image_1.jpg",
        "https://i.pinimg.com/736x/1e/c9/ff/1ec9ff8b23fd72e3136141e0d513dbac.jpg",
        "https://api.kashik.net//story_covers/image_6.jpg",
        "https://api.kashik.net//story_covers/image_3.jpg",
    ];


    const cards = [
        { id: 1, text: 'House of B Caddebostan', rating: 4.5 },
        { id: 2, text: 'Levantine de Nata', rating: 3.5 },
        { id: 3, text: 'Hommie House', rating: 5 },
        { id: 4, text: 'Irish Pub Caddebostan', rating: 4.5 },
        { id: 5, text: 'Ara Güler Kafe', rating: 3.5 },
    ];

    // Swing eventlerinin kurulumu
    useEffect(() => {
        if (stackRef.current) {
            const stack = stackRef.current;

            // Kart fırlatıldığında hangi yöne gittiğini gösterelim
            stack.on("throwout", (e) => {
                const cardId = e.target.getAttribute("data-id");
                const directionSymbol = e.throwDirection;
                // Symbol(LEFT) veya Symbol(RIGHT) ifadesini string’e çevirme
                const direction =
                    directionSymbol?.description ||
                    directionSymbol.toString().replace("Symbol(", "").replace(")", "");

                if (direction === "LEFT") {
                    console.log(`Kart ${cardId} SOLA atıldı.`);
                } else if (direction === "RIGHT") {
                    console.log(`Kart ${cardId} SAĞA atıldı.`);
                } else {
                    console.log(`Kart ${cardId} atıldı, yön: ${direction}`);
                }
                setSwipeMessage(`Kart ${cardId} fırlatıldı => Yön: ${direction}`);
            });

            // Geri gelme (throwin) normalde tetiklenmez, ama yine de dinleyebiliriz
            stack.on("throwin", (e) => {
                console.log("Kart geri gelmeye çalıştı, ama isThrowingOut => true engelliyor.");
            });
        }
    }, [selectedMode]);





    const NewTarget = ({ name, color = "secondary", icon = "person_add", style, white = "false", subtext = "Add to highligts" }) => (
        <SoftBadge style={{ style, marginInline: "-6px", padding: "0px" }} variant="gradient" badgeContent={
            <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "auto", minWidth: "220px" }}>
                <SoftBox mr={2}>
                    <SoftAvatar src={""} size="sm" icon={icon} iconColor={white == "false" ? "white" : "rgba(54, 54, 54, 0.65)"} variant="contained" iconSize="24px !important" />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" color={white == "false" ? "white" : "secondary"} fontSize="14px" fontWeight="medium">
                        {name}
                    </SoftTypography>
                    <SoftTypography variant="caption" color={white == "false" ? "white" : "secondary"} fontWeight="small">
                        {subtext}
                    </SoftTypography>
                </SoftBox>

            </SoftBox>
        } color={color} size="xl" container />
    );

    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "middle",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2, backdropFilter: "blur(1px)" }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        backgroundColor: "rgba(0, 0, 0, 0.8) !important", // Menü arka plan rengi
                        color: "white", // Menü metin rengi
                    },
                    className: "MuiPaper-root MuiMenu-paper MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-bv446k-MuiPaper-root-MuiMenu-paper-MuiPopover-paper"
                },
            }}

        >

            <MenuItem onClick={() => handleMenuSelection(1)}>
                <NewTarget name="Discover" subtext="Turn the explore mode" color="primary" icon="explore" />
            </MenuItem>

            <MenuItem onClick={() => handleMenuSelection(2)}>
                <NewTarget name="Social" subtext="Your followings mode" color="green" icon="people" />
            </MenuItem>

            <MenuItem onClick={() => handleMenuSelection(0)}>
                <NewTarget name="Highlights" subtext="Show your highlights" color="flatwhite" white="true" icon="bookmark" />
            </MenuItem>

        </Menu>
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/foodie/${username}`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/map/follow/${username}`, { withCredentials: true });
                setDataFollow(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const buttons = ["Discover", "Social", "Highlights"];
    const buttonMode = { "0": { icon: "bookmark", bgColor: "rgb(54 54 54 / 59%)" }, "1": { icon: "explore", bgColor: "linear-gradient(310deg, rgba(121, 40, 202, 0.9), rgba(255, 0, 128, 0.9))" }, "2": { icon: "people", bgColor: "linear-gradient(310deg, rgba(0, 136, 99, 0.9), rgba(99, 232, 169, 0.9))" } };

    const storySource = [
        {
          type: "image",
          url: 'https://images.pexels.com/photos/9470805/pexels-photo-9470805.jpeg?w=300',
          duration: 5000
        },
        {
          type: "video",
          url: 'https://images.pexels.com/photos/9733197/pexels-photo-9733197.jpeg?w=300',
          duration: 5000
        }
      ]

    return (
        <Card
            mt={3}
            style={{
                height: "140%",
                background: "#353535",
                background: "rgb(245 245 245)",
                width: "100%",
                borderRadius: "0.5rem",
                boxShadow: "0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)"
            }}
        >
            <div style={{ marginLeft: window.innerWidth <= 1200 ? "0px" : "125px" }} className="topbar">

                <SoftButton variant="gradient" color="leaf" size="small" onClick={handleOpenMenu} style={{ marginTop: "122px", paddingInline: "70px", zIndex: "10", position: "fixed", background: buttonMode[selectedMode].bgColor }}>
                    <Icon sx={{ fontWeight: "bold" }}>{buttonMode[selectedMode].icon}</Icon>&nbsp; {t("map.select." + selectedMode)}
                </SoftButton>
                {renderMenu()}
                <div className="headers" style={{ background: "transparent", backdropFilter: "blur(0px)", zIndex: "-10" }}>


                    <div className="buttons-container" style={{ paddingInline: window.innerWidth <= 600 ? "0%" : "7%" }}>

                        {/* {"searchQuery" != "" ? buttons.map((btnText, idx) => (
                            <button
                                key={idx}
                                className={`header-button ${selectedButton === btnText ? 'selected' : ''}`}
                                onClick={() => handleButtonClick(btnText)}
                            >
                                {"btnText"}
                            </button>
                        )) : <></>} */}
                    </div>

                </div>

                <div className="search-bar-container" style={{ background: "#ffffff75", backdropFilter: "blur(15px)" }}>
                    <button className="back-button" onClick={"handleBackButtonClick"}><i className="fas fa-arrow-left"></i></button>
                    <div className="profile-text">
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search..."
                            value={""}
                            onChange={"handleInputChange"}
                        />
                    </div>
                    <button className="search-button"><i className="fas fa-search"></i></button>

                </div>

            </div>

            {selectedMode === 1 && (
                <div style={{ width: "100%", height: "100%", background: "white" }}>

                    <div style={{
                        position: 'fixed', // Fixed position ile ekranın ortasında yer almasını sağlıyoruz
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "340px",
                        height: "605px",
                        zIndex: 99999999,
                        marginLeft: window.innerWidth <= 1200 ? "0px" : "125px"
                    }}>
                        <Swing
                            config={swingConfig}
                            setStack={(stack) => { stackRef.current = stack; }}
                            className="stack"
                            tagName="div"
                        >
                            {cards.map((card) => (
                                <div
                                    key={card.id}
                                    data-id={card.id}

                                    name={card.text + " " + card.id}
                                    style={{
                                        backgroundImage: `url(${"card.image"})`,
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        background: '#fff',
                                        borderRadius: '10px',
                                        boxShadow: '0px 10px 30px rgba(0,0,0,0.1)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        userSelect: 'none',
                                        backgroundSize: "cover",

                                    }}
                                >
                                    <StoryCard name={card.text} image={stories[card.id]} detail={card.rating} >
                                        
                                    </StoryCard>


                                </div>
                            ))}
                        </Swing>

                        {/* {swipeMessage && (
                            <SoftBox mt={2} textAlign="center">
                                <SoftTypography variant="h6" color="secondary">{swipeMessage}</SoftTypography>
                            </SoftBox>
                        )} */}
                    </div>



                    <div style={{ position: "fixed", zIndex: "100", bottom: "100px", width: "100%", display: "flex", justifyContent: "space-between", paddingInline: "25px", paddingLeft: window.innerWidth <= 1200 ? "25px" : "275px" }}>
                        <button className="back-button" style={{ border: "solid 2px #cdcdcd45" }} onClick={() => { /* handleBackButtonClick */ }}><FontAwesomeIcon icon={faLocationArrow} /></button>
                        <button className="back-button" style={{ border: "solid 2px #cdcdcd45" }} onClick={() => { /* handleBackButtonClick */ }}><TbPlayCardStarFilled /></button>
                    </div>
                </div>
            )}

            <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                height="122%"
                width="100%"
                style={{ position: "relative" }}

            >
                <Map data={selectedMode=== 0 ? data.foodie : selectedMode === 2 ? dataFollow.highlights : []} current={true} />
                {/* <Map data={{
            "route_list_items": [
                {
                    "type": "CB",
                    "item": {
                        "id": 5,
                        "name": "Baran Et Mangal",
                        "google_id": "ChIJGUkJ8Yq5yhQRYU_ASLmtDCk",
                        "address": "",
                        "lng": "28.954802",
                        "lat": "41.017470",
                        "interaction_count": 0,
                        "total_score": "0.00",
                        "calculated_score": "0.00",
                        "created_by": 1
                    },
                    "order": 0
                },
                {
                    "type": "CR",
                    "item": {
                        "id": 1,
                        "name": "The Blue Mosque",
                        "google_id": "ChIJ4fRwZb25yhQRpHwVijb3LeU",
                        "address": "Binbirdirek, At Meydanı Fatih, İstanbul",
                        "lng": "28.976810",
                        "lat": "41.005400"
                    },
                    "order": 0
                }
            ]
        }} /> */}
            </SoftBox>
        </Card>
    );
};

export default Page;
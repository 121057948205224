import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import ProfilesList from 'examples/Lists/ProfilesList';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";


import Stories from "examples/Stories/PhotoStories";
import EatlistCards from "examples/EatlistCards";
import RouteCards from "examples/RouteCards";
import RatingCards from "examples/RatingRestaurantCards";
import Map from 'examples/Maps';
import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */

function ProfileCardSpecial(data) {
  const { t } = useTranslation();
  const safeValue = (value) => value === null ? "-" : value;



  const SectionTitle = ({ title, style }) => (
    <h3 className="section-title" style={{ marginLeft: "60px" }}>{title}</h3>
  );

  return (



    <SoftBox position="relative">

      <SectionTitle title=" ‎" />
      <Card
        mt={3}
        sx={{
          height: { xs: "250px", md: "300px" },
        }}
        style={{

          background: "#353535",
          background: "rgb(245 245 245)",
          width: "100%",
          borderRadius: "0.5rem",
          boxShadow: "0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)"
        }}
      >
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          height="112%"
          width="100%"
          style={{ position: "relative" }}

        >
          <Map data={data?.data?.foodie} />
        </SoftBox>
      </Card>

      <SectionTitle title={t("latest.stories")} />

      <Stories storiesData={data?.data?.stories} />

      <SectionTitle title={t("latest.eatlists")} />

      <EatlistCards eatlistData={data?.data?.eatlists} maxCount={3} />

      <SectionTitle title={t("latest.ratings")} />

      <RatingCards placeData={data?.data?.foodie?.[0]?.ratings} maxCount={6} />

      {/* <SectionTitle title="Latest Routes" />

          <RouteCards eatlistData={[]} /> */}

    </SoftBox>





  )
}

export default ProfileCardSpecial

import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import axios from "axios"


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPersonWalkingLuggage, faSplotch, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import placeIcon2 from "assets/images/logos/icon-place.png";
import placeIcon from "assets/images/logos/apple-icon2-x1.png";

import burceMars2 from "assets/images/bilal.alemdaroglu.png";
import curved0 from "assets/images/curved-images/white-curved.jpeg";

import { useTheme } from '@mui/material/styles';


/* eslint-disable react/prop-types */

function Header(props) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const [usersActiveMenu, setUsersActiveMenu] = useState(null);
  const closeUsersActiveMenu = () => setUsersActiveMenu(null);
  const openUsersActiveMenu = (event) => setUsersActiveMenu(event.currentTarget);

  const [selectedMenu, setSelectedMenu] = useState(null);


  useEffect(() => {
    const menuDict =
    {

      "3": "Favorite",
      "2": "Pit Stop",
      "1": "Liked",
      "0": "Discover",
      "-1": "Eatlist"
    }
    setSelectedMenu(menuDict[String(props.data?.result?.foodie_tags)]); // foodie_tags değiştiğinde selectedMenu güncellenir
  }, [props.data]);

  const { id, foodie } = useParams();

  useEffect(() => {
    if (foodie && typeof foodie !== "undefined") {
      handleSetTabValue(11);
    }
  }, [foodie]);
  

  const theme = useTheme();
  const { functions, palette } = theme;
  const { rgba, linearGradient } = functions;
  const { gradients } = palette;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("horizontal")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);
    props.onChange(newValue);
  };

  const handleButtonClick = () => {
    window.location.href = `/cv/${props.data.id}`;
  };

  const handleMenuSelection = async (menuName) => {
    try {

      const menuDict = {
        "Starred": 4,
        "Favorite": 3,
        "Pit Stop": 2,
        "Discover": 0,
        "Eatlist": -1,
      };
      const menuNumber = menuDict[menuName];
      // API'ye POST isteği at
      const response = await axios.post(
        `${window.BASE_URL}/review/update-bookmark`,
        { status: menuNumber, google_id: id },
        { withCredentials: true }
      );

      // Başarılı olursa seçili menüyü güncelle
      if (response.status === 200) {
        setSelectedMenu(menuName);
      }
    } catch (error) {
      console.error("API Hatası:", error);
    } finally {
      closeUsersActiveMenu(); // Menü kapatılır
    }
  };

  const NewTarget = ({ name, color = "secondary", icon = "person_add", style, white = "false", subtext = "Add to highligts" }) => (
    <SoftBadge style={{ style, marginInline: "-6px", padding: "0px" }} variant="gradient" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "auto", minWidth: "220px" }}>
        <SoftBox mr={2}>
          <SoftAvatar src={""} size="sm" icon={icon} iconColor={white == "false" ? "white" : "#aaa"} variant="contained" iconSize="24px !important" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
          <SoftTypography display="inline" variant="body2" verticalAlign="middle" color={white == "false" ? "white" : "secondary"} fontSize="14px" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color={white == "false" ? "white" : "secondary"} fontWeight="small">
            {subtext}
          </SoftTypography>
        </SoftBox>

      </SoftBox>
    } color={color} size="xl" container />
  );

  const menuColors = {
    Starred: { color: "warning", icon: "star" },
    Favorite: { color: "primary", icon: "favorite" },
    "Pit Stop": { color: "info", icon: "place" },
    Discover: { color: "green", icon: "flag" },
    Eatlist: { color: "flatwhite", icon: "add" },
  };

  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      {/* <MenuItem onClick={() => handleMenuSelection("Starred")}>
        <NewTarget name="Starred" color="warning" icon="star" />
      </MenuItem> */}
      <MenuItem onClick={() => handleMenuSelection("Favorite")}>
        <NewTarget name="Favorite" color="primary" icon="favorite" />
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection("Pit Stop")}>
        <NewTarget name="Pit Stop" color="info" icon="place" />
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection("Discover")}>
        <NewTarget name="Discover" color="green" icon="flag" />
      </MenuItem>
      <MenuItem onClick={""}>
        <NewTarget
          name="Eatlist"
          color="flatwhite"
          icon="add"
          white="true"
          subtext="Add to an eatlist"
        />
      </MenuItem>
    </Menu>
  );


  return (
    <SoftBox position="relative">

      {!props.data || props.data.result?.in_list_count > 0 ? (
        <DashboardNavbar absolute light data={props.data} />
      ) : (
        <DashboardNavbar absolute dark data={props.data} />
      )}



      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="13.75rem"
        borderRadius="xl"
        mt={-3}
        style={{ borderStartEndRadius: "0px", borderStartStartRadius: "0px" }}
        sx={{
          backgroundImage: !props.data.result
            ? linearGradient(
              rgba(gradients.light.main, 0.6),
              rgba(gradients.light.state, 0.6)
            )
            : props.data?.result?.in_list_count == 0
              ? linearGradient(
                rgba(gradients.light.main, 0.6),
                rgba(gradients.light.state, 0.6)
              )
              : `${linearGradient(
                // rgba(gradients.warning.state, 1.0),
                // rgba(gradients.leaf.main, 1.0)
                rgba(gradients.light.main, 0.1),
                rgba(gradients.light.state, 0.1)
              )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              src={props.data?.result?.place_type != "1" ? "" : placeIcon}
              alt="profile-image"
              variant="rounded"
              size="xxl"
              shadow="sm"
              isLoading={!props.data?.result}
              icon="place"
              iconColor="#434343"
              iconSize="64px !important"

            />
          </Grid>

          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>

              <SoftTypography variant="h5" fontWeight="medium" isLoading={!props.data?.result} skeletonWidth="120px">
                {props.data?.result?.name}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium" skeletonWidth="100px" isLoading={!props.data?.result}>
                {props.data?.result?.address}
              </SoftTypography>
            </SoftBox>
          </Grid>

          {props.data?.result?.foodie_comment != "undefined" && props.data?.result?.place_type == "1" ?
            <Grid item xs={12} md={4} lg={5} sx={{ ml: "auto", mt: "30px", gap: "10px", display: "flex", flexDirection: { xs: "column", md: "row" } }} >

              <SoftButton
                onClick={openUsersActiveMenu}
                color={selectedMenu ? menuColors?.[selectedMenu]?.color : "white"}
                variant={selectedMenu ? "gradient" : "contained"}
                isLoading={!props.data?.result}
                fullWidth
              >
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "5px" }}
                  fontSize="small"
                  color="dark"
                >
                  {selectedMenu ? menuColors?.[selectedMenu]?.icon : "bookmark"}
                </Icon>
                {selectedMenu || "Bookmark"}
              </SoftButton>

              {renderMenu(usersActiveMenu, closeUsersActiveMenu)}


              {props.data?.result?.foodie_comment > 0 ?
                <SoftButton id="printButton" onClick={() => handleSetTabValue(11)} color="light" variant="gradient" isLoading={!props.data?.result} fullWidth>
                  <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "5px" }} fontSize="small" color="dark">
                    chat
                  </Icon>  Rerate
                </SoftButton>
                :
                <SoftButton id="printButton" onClick={() => handleSetTabValue(10)} color="dark" variant="gradient" isLoading={!props.data?.result} fullWidth>
                  <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "5px" }} fontSize="small" color="dark">
                    chat
                  </Icon>  Rate
                </SoftButton>}
            </Grid> :
            props.data?.result?.place_type != "1"
              ?
              <Grid item xs={12} md={4} lg={5} sx={{ ml: "auto", mt: "30px", gap: "10px", display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "flex-end" }} >

                <SoftButton
                  onClick={openUsersActiveMenu}
                  color={selectedMenu ? menuColors?.[selectedMenu]?.color : "dark"}
                  variant={selectedMenu ? "gradient" : "gradient"}
                  isLoading={!props.data?.result}
                  fullWidth

                >
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "5px" }}
                    fontSize="small"
                    color="dark"
                  >
                    {selectedMenu ? menuColors?.[selectedMenu]?.icon : "route"}
                  </Icon>
                  {selectedMenu || "Add Route"}
                </SoftButton>

                {renderMenu(usersActiveMenu, closeUsersActiveMenu)}
              </Grid>
              :

              ""}


          {props.data?.result?.place_type != "1" ? "" : ( 
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }} >
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={(event, newValue) => handleSetTabValue(newValue)}
                  sx={{ background: "transparent" }}
                >


                  
                    <Tab value={0} label="Explore" icon={<Cube />} />
                    <Tab value={1} label="Menu" icon={<FontAwesomeIcon icon={faBook} />} />
                    <Tab value={2} label="Rating" icon={<FontAwesomeIcon icon={faSplotch} />} />
                  

                </Tabs>
              </AppBar>
            </Grid>
          )} 
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;

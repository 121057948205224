import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer, InfoWindow } from '@react-google-maps/api';
import { useRef } from 'react';


import pinIcon from "assets/images/small-logos/Pin-location.png";
//import pinIcon from "assets/images/small-logos/pin-3-0.png";
import pinIcon0 from "assets/images/small-logos/pin-0-1.png";
import pinIcon0s from "assets/images/small-logos/pin-0-1-s.png";
import pinIcon3 from "assets/images/small-logos/pin-3-1.png";
import pinIcon2 from "assets/images/small-logos/pin-2-1.png";
import pinIconX from "assets/images/small-logos/pin-x-1.png";

import currentDot from "assets/images/small-logos/current-dot.png";
import currentDot2 from "assets/images/small-logos/blue-dot2.png";
//import { color } from 'html2canvas/dist/types/css/types/color';
/* eslint-disable react/prop-types */

const Map = ({ data, type = "point", current = false }) => {
  const [response, setResponse] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  const mapRef = useRef(null); // Harita referansı

  const [center, setCenter] = useState({ lat: 41.0082, lng: 28.9784 }); // Varsayılan konum

  const [currentLocation, setCurrentLocation] = useState(null); // Kullanıcının konumu

  useEffect(() => {
    // Kullanıcının mevcut konumunu almak için
    if (current) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const location = { lat: latitude, lng: longitude };
            setCurrentLocation(location); // Kullanıcının konumu
            setCenter(location); // Harita merkezi
          },
          (error) => {
            console.error("Geolocation error:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  }, []);


  const mapStyles = {
    height: '100vh',
    width: '100%',
    outline: 'none',
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    draggable: true,
    disableDoubleClickZoom: false,
    scrollwheel: true,
    keyboardShortcuts: false,
    styles: [
      {
        featureType: "all",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }], // Arka plan rengini beyaz yap
      },
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        //stylers: [{ color: "#ffffff" }],
        //stylers: [{ color: "#cfddec" }],
        stylers: [{ color: "#B5CCE2" }],
        //stylers: [{ color: "#d8e8eb"}],
        //stylers: [{ color: "#8795a4"}],
        //stylers: [{color: "#B8BECD"}]
        //stylers: [{color: "#ff8200"}]
        //stylers: [{ color: "#b7c5cf" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#434343" }],
      },
      {
        featureType: "landscape",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{ color: "#f7f8f9" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ visibility: "off" }],
      },
    ]
  };

  const directionsCallback = useCallback((res) => {
    if (res !== null) {
      if (res.status === 'OK') {
        setResponse(res);
      } else {
        console.log('response: ', res);
      }
    }
  }, []);

  const waypoints = useMemo(() => {
    if (data?.route_list_items) {
      return data.route_list_items
        .filter(item => !isNaN(parseFloat(item.item.lat)) && !isNaN(parseFloat(item.item.lng)))
        .map(item => ({
          location: { lat: parseFloat(item.item.lat), lng: parseFloat(item.item.lng) },
          stopover: true,
          name: item.item.name,
          user_tag: -2,
        }));
    } else if (data?.[0]?.ratings || data?.[0]?.other_tags) {
      const combinedTags = [
        ...(data[0].ratings || []),
        ...(data[0].other_tags || []),
      ];

      return combinedTags
        .filter(tag => !isNaN(parseFloat(tag.restaurant.lat)) && !isNaN(parseFloat(tag.restaurant.lng)))
        .map(tag => ({
          location: { lat: parseFloat(tag.restaurant.lat), lng: parseFloat(tag.restaurant.lng) },
          stopover: true,
          name: tag.restaurant.name,
          user_tag: tag.restaurant.user_tag,
        }));
    }
    else if (data?.result) {
      return [{
        location: { lat: parseFloat(data?.result?.lat), lng: parseFloat(data?.result?.lng) },
        stopover: true,
        name: data?.result.name,
        user_tag: -2,
      }] ;
    }

    else if (data?.[0]?.restaurant) {
      console.log("data.restaurant", data?.[0].restaurant);
      return data?.map(item => ({
        location: { lat: parseFloat(item?.restaurant?.lat), lng: parseFloat(item?.restaurant?.lng) },
        stopover: true,
        name: item.restaurant.name,
        user_tag: item.restaurant.most_highlight,
      }));
    }

    return [];
  }, [data]);


  useEffect(() => {
    if (waypoints.length > 0 && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      waypoints.forEach(waypoint => bounds.extend(waypoint.location)); // Tüm waypoint'leri kapsayan alanı oluştur

      mapRef.current.fitBounds(bounds); // Haritayı bu alana hizala
      setCenter(bounds.getCenter().toJSON()); // Merkezi güncelle
    }
    else if (data?.result) {
      setCenter(waypoints[0]?.location);
    }
  }, [waypoints]);


  const origin = waypoints[0]?.location || center;
  const destination = waypoints[waypoints.length - 1]?.location || center;

  const handleMarkerClick = (location, index) => {
    setActiveMarker(index); // Aktif marker'ı ayarla
    setCenter(location); // Harita merkezini güncelle

    // Haritayı zoom ve animasyonla güncelle
    if (mapRef.current) {
      mapRef.current.panTo(location); // Merkezi animasyonla taşı
      mapRef.current.setZoom(15); // Zoom seviyesini ayarla
    }
  };

  useEffect(() => {
    if (response === null && origin && destination && waypoints.length > 1) {
      setResponse(null);
    }
  }, [origin, destination, waypoints, response]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyD76O6M6qnbZrGbfvU9e6btN26PaMhcY0I" // Gerçek API anahtarınızı buraya ekleyin
      onLoad={() => setIsLoaded(true)}
    >
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={center}
          options={{...options,backgroundColor: "#B5CCE2"}}
          tabIndex={-1}
          onLoad={map => (mapRef.current = map)} // Harita referansını sakla

        >

          {currentLocation && current && (
            <Marker
              position={currentLocation}
              title="Your Location"
              icon={{
                url: currentDot2,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )}

          {waypoints.map((waypoint, index) => (
            <Marker
              key={index}
              position={waypoint.location}
              icon={{
                url: waypoint.user_tag === 3 ? pinIcon3 : waypoint.user_tag === 2 ? pinIcon2 : waypoint.user_tag === 0 ? (activeMarker === index ? pinIcon0s : pinIcon0) : waypoint.user_tag === -2 ? pinIcon : waypoint.user_tag ? pinIcon : pinIconX,
                scaledSize: activeMarker === index
                  ? (type === "route"
                    ? new window.google.maps.Size(64, 64) // Aktif marker için 2x
                    : new window.google.maps.Size(128, 128)) // Aktif marker için 2x
                  : (type === "route"
                    ? new window.google.maps.Size(32, 32) // Varsayılan boyut
                    : new window.google.maps.Size(64, 64)), // Varsayılan boyut
              }}
              title={`${waypoint.name}`}
              animation={activeMarker === index ? window.google.maps.Animation.BOUNCE : null} // Marker animasyonu
              onClick={() => handleMarkerClick(waypoint.location, index)}
            >
              {activeMarker === index && (
                <InfoWindow
                  position={waypoint.location} // Bilgi penceresi marker'ın konumunda açılır
                  onCloseClick={() => setActiveMarker(null)} // Kapatılınca aktif marker sıfırlanır
                >
                  <div>
                    <h4>{waypoint.name}</h4>
                    {/* <p>User Tag: {waypoint.user_tag}</p>
                    <p>Latitude: {waypoint.location.lat}</p>
                    <p>Longitude: {waypoint.location.lng}</p> */}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}

          {/* DirectionsService sadece response boş olduğunda çağrılır */}
          {type == "route" && origin && destination && waypoints.length > 1 && !response && (
            <DirectionsService
              options={{
                origin: origin,
                destination: destination,
                waypoints: waypoints.slice(1, -1),
                travelMode: 'WALKING',
              }}
              callback={directionsCallback}
            />
          )}

          {type == "route" && response && (
            <DirectionsRenderer
              options={{
                directions: response,
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: '#434343',
                  strokeOpacity: 0.6,
                  strokeWeight: 6,
                },
              }}
            />
          )}
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default Map;


// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Settings page components
import BaseLayout from "layouts/settings/components/BaseLayout";
import Sidenav from "layouts/settings/components/Sidenav";
import Header from "layouts/settings/components/Header";
import DivHeader from "layouts/headers/NormalHeader"
import BasicInfo from "layouts/settings/components/BasicInfo";

import curved0 from "assets/images/curved-images/curved-food.png";

import { useTranslation } from 'react-i18next';




function Settings() {
  const { t } = useTranslation();
  let dataHeader1 = {
    "title": t("setting.header.title"),
    "line1": t("setting.header.subtitle"),
    "color": "dark",
    "image": curved0,
  }
  return (
    <BaseLayout>
      <SoftBox mt={0}>
      <DivHeader data={dataHeader1} />
      <br/>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                
      
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default Settings;

import React from 'react'

import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import MiniFollowCard from "examples/Cards/StatisticsCards/MiniFollowCard";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


/* eslint-disable react/prop-types */

function ProfileCardSpecial({ data, title, onChange }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Güvenli değer kontrolü: string veya sayı döndürür
  const safeValue = (value) => (value === null || typeof value === 'object') ? "-" : value;


  const handleSetTabValue = (newValue) => {
    navigate(`/foodie/${newValue}`);
    onChange(0);
  };

  const SectionTitle = ({ title }) => (
    <h3 className="section-title" style={{ marginLeft: "60px" }}>{title}</h3>
  );

  return (
    <SoftBox position="relative">
      <SectionTitle title={title} />
      <Grid container spacing={2} pb={12} sx={{
        paddingX: {
          xs: 0,  // Küçük ekranlar için
          sm: 3,  // Orta ekranlar için
          md: 6,  // Büyük ekranlar için
          lg: 8,  // Daha büyük ekranlar için
          xl: 10  // En büyük ekranlar için
        }
      }}>
        <Grid container spacing={2} p={3} pr={0} pl={2} pb={0}>
          {data?.map((item, idx) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              key={idx}
              onClick={() => handleSetTabValue(item.username)}
              style={{ cursor: "pointer" }} // tıklanabilir olduğu belli olsun
            >
              <MiniFollowCard
                bgColor="white"
                title={{ fontWeight: "medium", text: safeValue(item.username) }}
                count={safeValue(item.full_name)}
                icon={{ color: "dark", component: "person" }}
                direction="left"
                image={item.foodie_profilephoto}
                isFollow={!item.is_following}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default ProfileCardSpecial;

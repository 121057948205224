// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Search from "layouts/search";
import Place from "layouts/place";
import Explore from "layouts/explore";
import Eatlist from "layouts/eatlist";
import Route from "layouts/route";
import Category from "layouts/category";

// import Orgchar from "layouts/orgchar";
import Create from "layouts/create";
import MapPage from "layouts/map";
import CreateEatlist from "layouts/eatlist/create";
import Settings from "layouts/settings";
// import Tables from "layouts/tables";
// import Performans from "layouts/performans-list";
// import Graphs from "layouts/graphs";
// import Isealim from "layouts/ise-alim";
// import StoryHunter from "layouts/storyhunter";
// import Konuk from "layouts/konuk";
// import Intibak from "layouts/intibak";
// import IntibakCikis from "layouts/intibakcikis";
// import BilgiNot from "layouts/bilgi-notlari";
// import Disiplins from "layouts/disiplins";
// import Freelance from "layouts/freelancer";
// import Muhabir from "layouts/muhabir";
// import Yayin from "layouts/yayin";
// import Rota from "layouts/rota";
// import Izinler from "layouts/izinler";
// import Hedefler from "layouts/hedefler";
// import KanalRapor from "layouts/kanal-rapor";

//import Mevzuat from "layouts/mevzuat";
// import Kisaltmalar from "layouts/kisaltmalar";
// import Sozluk from "layouts/sozluk";
// import Servis from "layouts/servis";
// import Istanimi from "layouts/istanimi";
// import Editoryel from "layouts/editoryel";

// import Yapayzeka from "layouts/yapayzeka";
// import Degerlendirme from "layouts/degerlendirme";


// import Billing from "layouts/billing";
// import VirtualReality from "layouts/virtual-reality";
// import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import TwoFactor from "layouts/authentication/two-factor";
//import CV from "layouts/cv"
//import Rapor from "layouts/rapor"
import SignUp from "layouts/authentication/sign-up";

// Soft UI Dashboard React icons
// import Shop from "examples/Icons/Shop";
// import Office from "examples/Icons/Office";
// import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
// import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
// import CreditCard from "examples/Icons/CreditCard";
// import Cube from "examples/Icons/Cube";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScaleLeft, faBellConcierge, faBookmark, faBookOpen, faCompass, faFileAlt, faFishFins, faHamburger, faHouse, faLayerGroup, faList, faMagnifyingGlass, faMapLocation, faPenNib, faPepperHot, faPerson, faPizzaSlice, faRightFromBracket, faRightToBracket, faSatelliteDish, faSquarePlus, faUser, faUserPlus, faUtensils } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faHeadSideVirus } from '@fortawesome/free-solid-svg-icons'

import FastfoodIcon from '@mui/icons-material/Fastfood';


import React, { useEffect, useState } from 'react';
//import { Settings } from "@mui/icons-material";



let userPermissions = {

  //Kurum
  PageKurum: true,

  AccKurum: true,
  AccGraphs: true,
  AccOrgchar: true,

  //IK
  PageIK: true,

  AccTables: true,
  AccIsealim: true,
  AccPerformans: true,
  AccBilgiNot: true,
  AccIntibak: true,

  //MALI
  PageMali: true,

  AccKonuk: true,
  AccFreelance: true,
  AccStoryHunter: true,

  //HUKUKI
  PageHukuki: true,

  AccMahkeme: true,
  AccDisiplins: true,
  AccSozlesme: true,
  AccGorusler: true,

  //KOORDINASYON
  PageKoordinasyon: true,

  AccIzinler: true,
  AccRota: true,
  AccServis: true,

  //STRATEJI
  PageStrateji: true,

  AccMuhabir: true,
  AccYayin: true,
  AccKpi: true,
  AccEgitim: true,

  //POLITIKALAR
  PagePolitikalar: true,

  AccMevzuat: true,
  AccKisaltmalar: true,
  AccIstanimi: true,
  AccSozluk: true,
  AccEditoryel: true,

};


const routes = (t) => [

  {
    type: "collapse",
    name: t && t("pages.signin") ? t("pages.signin") : "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <FontAwesomeIcon icon={faRightToBracket} size="xs" />,
    component: <SignIn />,
    permissions: ['PageNoAuth'],
    children: [
    ]
  },

  {
    type: "collapse",
    name: t && t("pages.signup") ? t("pages.signup") : "Sign In",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <FontAwesomeIcon icon={faUserPlus} size="xs" />,
    component: <SignUp />,
    permissions: ['PageNoAuth'],
    children: [
    ]
  },



  {
    type: "collapse",
    name: t && t("pages.explore") ? t("pages.explore") : "Explore",
    key: "explore",
    route: "/explore",
    icon: <FontAwesomeIcon icon={faCompass} size="xs" />,
    component: <Explore />,
    permissions: ['PageKurum'],
    children: [
    ]
  },

  {
    type: "collapse",
    name: t && t("pages.search") ? t("pages.search") : "Search",
    key: "search",
    route: "/search",
    icon: <FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />,
    component: <Search />,
    permissions: ['PageKurum'],
    children: [
    ]
  },

  {
    type: "collapse",
    name: t && t("pages.create") ? t("pages.create") : "Create",
    key: "create",
    route: "/create",
    icon: <FontAwesomeIcon icon={faSquarePlus} size="xs" />,
    component: <Create />,
    permissions: ['PageKurum'],
    children: [
    ]
  },



  {
    type: "collapse",
    name: t && t("pages.map") ? t("pages.map") : "Map",
    key: "map",
    route: "/map",
    icon: <FontAwesomeIcon icon={faMapLocation} size="xs" />,
    component: <MapPage />,
    permissions: ['PageKurum'],
    children: [
    ]
  },




  {
    type: "collapse",
    name: t && t("pages.foodie") ? t("pages.foodie") : "Foodie",
    key: "foodie",
    route: "/foodie/:id",
    icon: <FontAwesomeIcon icon={faUser} size="xs" />,
    component: <Profile />,
    noCollapse: true,
    children: [
    ]
  },

  {
    type: "title",
    name: "User",
    key: "user",
    route: "/user/:id",
    icon: <FontAwesomeIcon icon={faUser} size="xs" />,
    component: <Profile />,
    noCollapse: true,
    children: [
    ]
  },

  {
    type: "title",
    name: "Foodie",
    key: "foodie",
    route: "/foodie/:id/:view",
    icon: <FontAwesomeIcon icon={faUser} size="xs" />,
    component: <Profile />,
    noCollapse: true,
    children: [
    ]
  },

  {
    type: "title",
    name: "Create Eatlist",
    key: "eatlist",
    route: "/create/eatlist",
    icon: <FontAwesomeIcon icon={faSquarePlus} size="xs" />,
    component: <CreateEatlist />,
    permissions: ['PageKurum'],
    children: [
    ]
  },

  {
    type: "title",
    name: "Eatlist",
    key: "eatlist",
    route: "/eatlist/:id",
    icon: <FontAwesomeIcon icon={faUtensils} size="xs" />,
    component: <Eatlist />,
    permissions: ['PageKurum'],
    noCollapse: true,
    
  },
  {
    type: "title",
    name: "Highlight",
    key: "highlight",
    route: "/highlight/:username/:view",
    icon: <FontAwesomeIcon icon={faUtensils} size="xs" />,
    component: <Eatlist />,
    permissions: ['PageKurum'],
    noCollapse: true,
    
  },


  {
    type: "title",
    name: "Route",
    key: "route",
    route: "/route/:id",
    icon: <FontAwesomeIcon icon={faBookmark} size="xs" />,
    component: <Route />,
    permissions: ['PageKurum'],
    noCollapse: true,
  },

  {
    type: "title",
    name: "Place",
    key: "place",
    route: "/place/:id",
    icon: <FontAwesomeIcon icon={faBellConcierge} size="xs" />,
    component: <Place />,
    permissions: ['PageKurum'],
    noCollapse: true,
    
  },

  {
    type: "title",
    name: "Place",
    key: "place",
    route: "/place/:id/:foodie",
    icon: <FontAwesomeIcon icon={faBellConcierge} size="xs" />,
    component: <Place />,
    permissions: ['PageKurum'],
    noCollapse: true,
    
  },

  {
    type: "title",
    name: "Category",
    key: "category",
    route: "/category/:id",
    icon: <FontAwesomeIcon icon={faLayerGroup} size="xs" />,
    component: <Category />,
    permissions: ['PageKurum'],
    noCollapse: true,
    
  },

  

 
  // { type: "title", title: "Hesap Bilgileri", key: "account-pages" },
  {
    type: "title",
    name: "Profil",
    key: "profile",
    route: "/profile/:id",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },

  {
    type: "title",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <Document size="12px" />,
    component: <Settings />,
    noCollapse: true,
  },

  {
    type: "title",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <Document size="12px" />,
    component: <SignUp />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "2 Adımlı Doğrulama",
    key: "two-factor",
    route: "/authentication/two-factor",
    icon: <Document size="12px" />,
    component: <TwoFactor />,
    noCollapse: true,
  },
  // {
  //   type: "title",
  //   name: "CV",
  //   key: "cv",
  //   route: "/cv/:id",
  //   icon: <Document size="12px" />,
  //   component: <CV />,
  //   noCollapse: true,
  // },

  // {
  //   type: "title",
  //   name: "Rapor",
  //   key: "rapor",
  //   route: "/rapor/:id/:view",
  //   icon: <Document size="12px" />,
  //   component: <Rapor />,
  //   noCollapse: true,
  // },

];

const filterRoutesByPermissions = (routes, userPermissions,isAuthenticated) => {
  return routes
    .filter(route => {
      // Eğer ana rotanın özel bir izni varsa ve kullanıcının bu izni yoksa, çocuk rotalar kontrol edilir.
      if ((route.permissions && !route.permissions.some(permission => userPermissions[permission]))) {
        // Çocuk rotalar kontrol ediliyor...
        if (route.children && route.children.length > 0) {
          // Eğer çocuk rotalardan en az biri aktifse, ana rota aktif kabul edilir.
          return route.children.some(child => child.permissions.some(permission => userPermissions[permission]));
        }
        return false; // Ana rotanın izni yoksa ve çocuk rotalardan hiçbiri aktif değilse, ana rota listeye eklenmez.
      }
      return true; // Ana rotanın izni yoksa veya kullanıcı bu izne sahipse, ana rota listeye eklenir.
    })
    .map(route => {
      if (route.children && route.children.length > 0) {
        const filteredChildren = filterRoutesByPermissions(route.children, userPermissions);
        return { ...route, children: filteredChildren };
      }
      return route;
    });
};


const filterSideRoutesByPermissions = (routes, userPermissions, isAuthenticated) => {
  return routes
    .filter(route => {
      // Eğer kullanıcı oturum açmamışsa sadece PageNoAuth izni olan rotalar döndürülür
      if (!isAuthenticated) {
        return route.permissions && route.permissions.includes("PageNoAuth");
      }

      // Eğer ana rotanın özel bir izni varsa ve kullanıcının bu izni yoksa, çocuk rotalar kontrol edilir
      if (route.permissions && !route.permissions.some(permission => userPermissions[permission])) {
        // Çocuk rotalar kontrol ediliyor
        if (route.children && route.children.length > 0) {
          // Eğer çocuk rotalardan en az biri aktifse, ana rota aktif kabul edilir
          return route.children.some(child => child.permissions.some(permission => userPermissions[permission]));
        }
        return false; // Ana rotanın izni yoksa ve çocuk rotalardan hiçbiri aktif değilse, ana rota listeye eklenmez
      }

      return true; // Ana rotanın izni yoksa veya kullanıcı bu izne sahipse, ana rota listeye eklenir
    })
    .map(route => {
      if (route.children && route.children.length > 0) {
        const filteredChildren = filterRoutesByPermissions(route.children, userPermissions, isAuthenticated);
        return { ...route, children: filteredChildren };
      }
      return route;
    });
};




//const filteredRoutes = filterRoutesByPermissions(routes, userPermissions);



export const getFilteredRoutes = (userPermissions,isAuthenticated,t) => {
   const filteredRoutes = filterRoutesByPermissions(routes(t), userPermissions,isAuthenticated); 
   return filteredRoutes;
};

export const getFilteredSideRoutes = (userPermissions,isAuthenticated,t) => {
  const filteredRoutes = filterSideRoutesByPermissions(routes(t), userPermissions,isAuthenticated); 
  return filteredRoutes;
};

//console.log(filteredRoutes)

//export default filteredRoutes;






import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios"
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarMinus, faChartLine, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPersonWalkingLuggage, faSplotch, faUserClock, faUserTag, faUtensils } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import burceMars2 from "assets/images/bilal.alemdaroglu.png";
import curved0 from "assets/images/curved-images/curved-trt.png";
import SoftBadge from "components/SoftBadge";
import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */

function Header(props) {
  const { t } = useTranslation();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [isFollowing, setIsFollowing] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const { id, view } = useParams();

  const username = useSelector(state => state.auth.username);
  console.log(username)

  useEffect(() => {
    setIsFollowing(props.data?.foodie?.[0]?.is_following);
  }, [props.data]);

  useEffect(() => {
    if (view === "starred") {
      handleSetTabValue(11);
    }
    if (view === "favorite") {
      handleSetTabValue(11);
    }
    if (view === "liked") {
      handleSetTabValue(11);
    }
    if (view === "hyped") {
      handleSetTabValue(11);
    }
    if (view === "eatlist") {
      handleSetTabValue(1);
    }
    if (view === "rating") {
      handleSetTabValue(2);
    }
  }, [view]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleFollowToggle = async () => {
    try {
      const response = await axios.post(`${window.BASE_URL}/follow/${id}`, {}, { withCredentials: true });
      setIsFollowing(response.data.is_following);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);
    console.log(newValue);
    props.onChange(newValue);
  };

  const handleButtonClick = () => {
    window.location.href = `/follow/${id}`;
  };

  const handleEditButtonClick = () => {
    window.location.href = `/settings`;
  };

  const eatCount = props.data?.foodie?.[0]?.eatlist_item_count || 0;
  const guideCount = props.data?.foodie?.[0]?.guide_item_count || 0;
  const ratingCount = props.data?.foodie?.[0]?.ratings.length || 0;
  const followingCount = props.data?.foodie?.[0]?.following_count || 0;
  const followerCount = props.data?.foodie?.[0]?.follower_count || 0;

  const profilename = props.data?.foodie?.[0]?.username || "";

  let displayText = '';


  //displayText = `${followerCount} Follower • ${followingCount} Following • ${ratingCount} Ratings`;
  displayText =  profilename ? `@${profilename}`: "";

  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light data={props.data?.foodie} />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight={{ xs: "12rem", sm: "15.75rem" }}
        borderRadius="xl"
        sx={{
          backgroundImage: props.data?.foodie?.[0]?.foodie_coverphoto ? `url(${props.data?.foodie?.[0]?.foodie_coverphoto})` :

            (({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.light.main, 0.6),
                rgba(gradients.light.state, 0.6)
              )}, url(/static/media/white-curved.a5b7db662b0b14ab5534.jpeg)`),
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {
              <SoftAvatar
                src={props.data?.foodie?.[0]?.foodie_profilephoto ? props.data?.foodie?.[0]?.foodie_profilephoto : ""}
                alt="profile-image"
                variant="rounded"
                size="xxl"
                icon="person"
                iconColor="#d0d6dc"
                iconSize="85px"
                shadow="sm"
                borderRadius="4.75rem"
                style={{ borderRadius: "4.75rem" }}
                isLoading={props.isLoading}
                circular={true}

              />
            }
          </Grid>

          <Grid item>
            <SoftBox onClick={() => handleSetTabValue(0)}  height="100%" mt={0.5} lineHeight={1} display="grid" mr={1}>

              <SoftTypography variant="h5" fontWeight="medium" skeletonWidth="100px" isLoading={props.isLoading}>
                {props.data?.foodie?.[0]?.full_name}

                {
                  props.data?.foodie?.[0]?.verified ?
                  <Icon style={{marginLeft:"5px",paddingTop:"1.5px",background:"-webkit-linear-gradient(318deg, rgb(251 193 54), rgb(254, 131, 0)) text","-webkit-background-clip": "text","-webkit-text-fill-color":"transparent"}}> verified </Icon> : ""}
              </SoftTypography>

              <SoftTypography variant="button" color="secondary" fontWeight="regular"  skeletonWidth="70px" isLoading={props.isLoading}>
                {displayText}
                
              </SoftTypography>
              {/* <SoftBadge variant="gradient" badgeContent={"gourmet"} color="leaf" size="xs" container /> */}
              {/* <SoftBadge variant="gradient" badgeContent={"Gourmand"} color="light" size="xs" container /> */}

            </SoftBox>
          </Grid>

          <SoftBox style={{display:"flex",width:"400px", justifyContent:"space-around"}} pl={2}>
            <SoftBox onClick={() => handleSetTabValue(2)} mt={2} lineHeight={0} display={{ xs: "unset", sm: "unset" }} sx={{ width: { xs: "160%", sm: "auto" }, textAlign: { xs: "center", sm: "center" } }}>
              <SoftTypography display={{ xs: "inherit", sm: "none" }} variant="button" fontWeight="medium" color="#434343" isLoading={props.isLoading}>
                {ratingCount}
              </SoftTypography>
              <SoftTypography display={{ xs: "none", sm: "inherit" }} variant="h5" fontWeight="bold" color="#434343" isLoading={props.isLoading}>
                {ratingCount}
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                sx={{ fontSize: { xs: "11px", sm: "14px" }, color: { xs: "#656464 !important", sm: "#70747a" } }}
                color={{ xs: "#7b7c7d !important", sm: "#70747a" }}
                textTransform="capitalize"
                display={{ xs: "unset", sm: "unset" }}
                isLoading={props.isLoading}
              >
                {"Ratings"}
              </SoftTypography>
            </SoftBox>
            <SoftBox onClick={() => handleSetTabValue(3)} mt={2} lineHeight={0} display={{ xs: "unset", sm: "unset" }} sx={{ width: { xs: "160%", sm: "auto" }, textAlign: { xs: "center", sm: "center" } }}>
              <SoftTypography display={{ xs: "inherit", sm: "none" }} variant="button" fontWeight="medium" color="#434343" isLoading={props.isLoading}>
                {followerCount}
              </SoftTypography>
              <SoftTypography display={{ xs: "none", sm: "inherit" }} variant="h5" fontWeight="bold" color="#434343" isLoading={props.isLoading}>
                {followerCount}
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                sx={{ fontSize: { xs: "11px", sm: "14px" }, color: { xs: "#656464 !important", sm: "#70747a" } }}
                color={{ xs: "#7b7c7d !important", sm: "#70747a" }}
                textTransform="capitalize"
                display={{ xs: "unset", sm: "unset" }}
                isLoading={props.isLoading}
              >
                {"Followers"}
              </SoftTypography>
            </SoftBox>
            <SoftBox onClick={() => handleSetTabValue(4)} mt={2} lineHeight={0} display={{ xs: "unset", sm: "unset" }} sx={{ width: { xs: "160%", sm: "auto" }, textAlign: { xs: "center", sm: "center" } }}>
              <SoftTypography display={{ xs: "inherit", sm: "none" }} variant="button" fontWeight="medium" color="#434343" isLoading={props.isLoading}>
                {followingCount}
              </SoftTypography>
              <SoftTypography display={{ xs: "none", sm: "inherit" }} variant="h5" fontWeight="bold" color="#434343" isLoading={props.isLoading}>
                {followingCount}
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                sx={{ fontSize: { xs: "11px", sm: "14px" }, color: { xs: "#656464 !important", sm: "#70747a" } }}
                color={{ xs: "#7b7c7d !important", sm: "#70747a" }}
                textTransform="capitalize"
                display={{ xs: "unset", sm: "unset" }}
                isLoading={props.isLoading}
              >
                {"Following"}
              </SoftTypography>
            </SoftBox>

          </SoftBox>


          {id != username ? (!username || username == null ? "" : <Grid item xs={12} md={1.8} lg={1.8} sx={{ ml: "auto", mt: "30px" }}>
            <SoftButton id="printButto2n" onClick={handleFollowToggle} color={isFollowing ? "white": "dark"} variant="gradient" isLoading={props.isLoading} fullWidth>
            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                  {isFollowing ? "person_remove" : "person_add"}
                </Icon>&nbsp;  {isFollowing ? t("button.unfollow") : t("button.follow")}
              </SoftButton>
          </Grid> : "")
          :

          <Grid item xs={12} md={1.8} lg={1.8} sx={{ ml: "auto", mt: "30px" }}>
            <SoftButton id="printButton" onClick={handleEditButtonClick} color="dark" variant="gradient" fullWidth>
              <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                edit
              </Icon>&nbsp;  {t("button.edit")}
            </SoftButton>
          </Grid>
          }


        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;



// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHeart, faHouse, faIdCard, faMessage, faNoteSticky, faPersonWalkingLuggage, faSplotch, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

import { menuColors, menuDict, menuNum } from "assets/variables";
import { use } from "i18next";
import { useNavigate } from "react-router-dom";

/* eslint-disable react/prop-types */

// Custom styles for ComplexProjectCard
function ComplexProjectCard({ color, image, title, dateTime, description, members, owner, dropdown, ratingScore, raterCount, userTag, foodCount, id,foodiename }) {
  const navigate = useNavigate();
  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`;


    return (
      owner ?
        <SoftAvatar
          key={memberKey}
          icon="place"
          size="xs"
          bgColor="flatwhite"
          style={{ fontSize: "10px !important" }}
          iconSize="16px"
        /> : <SoftAvatar
          key={memberKey}
          icon="place"
          size="xs"
          bgColor="flatwhite"
          style={{ fontSize: "10px !important" }}
          iconSize="16px"
        />

    );
  });

  return (
    <Card style={{ overflow: "hidden", marginTop: "30px" }} >
      <SoftBox p={2}>
        <SoftBox style={{ margin: "-10px", background: "rgb(239 241 244)", marginBottom: "10px", height: "120px", borderRadius: "0.5rem" }}>

        </SoftBox>
        <SoftBox
          onClick={() => navigate('/place/' + id)}
          display="flex"
          alignItems="center"
          style={{ cursor: 'pointer' }}
        >
          <SoftAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={color}
            sx={{ p: 1, position: "absolute", filter: "blur(15px)", marginTop: "-40px" }}
          />
          <SoftAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={"flatwhite"}
            sx={{ p: 1, marginTop: "-40px" }}
          />

          <SoftBox ml={2} lineHeight={0}>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {title}
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" style={{ marginTop: "-10px" }}>{renderMembers}
              <SoftTypography variant="button" color="adress" fontWeight="regular" fontSize="10px" alignContent="center" marginLeft="3px">

                {owner}
              </SoftTypography>
            </SoftBox>
            <Divider />
          </SoftBox>

          {dropdown && (
            <SoftTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </SoftTypography>
          )}
          {dropdown.menu}
        </SoftBox>
        <SoftBox my={2} lineHeight={1} mx={1}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {""}
          </SoftTypography>
        </SoftBox>
        <div onClick={() => navigate('/place/' + id + "/"+ foodiename)} style={{ cursor: 'pointer' }} >
        <SoftBox my={2} lineHeight={1} mx={2} mb={3} mt={0}  >
          <SoftTypography variant="button" fontWeight={description ? "regular" : "regular"} color={description ? "text" : "placeholder"} display="flex" flexDirection="row">
            {userTag ?
              <SoftBox
                width="2.0rem"
                minWidth="2.0rem"
                height="2.0rem"
                bgColor="white"
                borderRadius="md"
                display="flex"
                mr={1}
                mt={-1}

                justifyContent="center"
                alignItems="center"
                color={color}
                shadow="md"
                fontSize="0.25rem"
                sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                  background: gradients[menuColors[menuNum[userTag]].color]
                    ? `${linearGradient(
                      rgba(gradients[menuColors[menuNum[userTag]].color].main, 0.9),
                      rgba(gradients[menuColors[menuNum[userTag]].color].state, 0.9)
                    )}, url(${"whiteCurved"})`
                    : `${linearGradient(
                      rgba(gradients.dark.main, 0.9),
                      rgba(gradients.dark.state, 0.9)
                    )}, url(${"whiteCurved"})`,
                })}
              >
                <Icon
                  sx={{
                    fontSize: "0.95rem !important",
                    backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                      gradients[color]
                        ? linearGradient("white", "white")
                        : linearGradient("white", "white"),

                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",

                  }}
                >
                  {menuColors[menuNum[userTag]].icon}
                </Icon>

              </SoftBox>
              : ""}
            {description ? description : userTag ? "No Description" : ""}
          </SoftTypography>
        </SoftBox>

        {/* <Divider /> */}

        <SoftBox display="flex" justifyContent="space-between" alignItems="center" style={{ padding: "30px", paddingBlock: "20px", background: "#f8f9fa", borderRadius: "1rem" }}>



          <SoftBox display="flex" flexDirection="row" lineHeight={0}>
            <SoftTypography variant="button" fontWeight="medium">
              {raterCount}
            </SoftTypography>
            {title ? <SoftTypography variant="button" fontWeight="medium" color="secondary" style={{ marginLeft: "5px" }}>
              Total Rating
            </SoftTypography> : ""}
          </SoftBox>

          <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center">
            <SoftTypography variant="button" fontWeight="medium">
              {ratingScore}
            </SoftTypography>
            <SoftBox display="flex" alignItems="center" justifyContent="center" style={{ width: "35px", height: "35px", marginRight: "-10px", marginLeft: "10px", background: "white", borderRadius: "1rem" }}>
              <FontAwesomeIcon icon={faSplotch} fontSize="1rem" color="#8392ab" />
            </SoftBox>
          </SoftBox>






        </SoftBox>
        </div>

        <SoftBox mt={3} mb={1} px={1}>
          {/* <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={6}>
              <SoftBox display="flex" alignItems="center">
                <SoftBox display="flex" alignItems="center" mr={2}>
                  <SoftTypography component="a" variant="body2" color="text">
                    <Icon fontSize="20px">favorite_border</Icon>&nbsp;
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    { ""}
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" mr={2}>
                  <SoftTypography component="a" variant="body2" color="text">
                    <Icon >mode_comment</Icon>&nbsp;
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    { ""}
                  </SoftTypography>
                </SoftBox>
                {/* <SoftBox display="flex" alignItems="center" mr={2}>
                                    <SoftTypography component="a" href="#" variant="body2" color="text">
                                        <Icon >share</Icon>&nbsp;
                                    </SoftTypography>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                        12
                                    </SoftTypography>
                                </SoftBox> 
              </SoftBox>
            </Grid>

          </Grid> */}


          {foodCount ? <> <Divider style={{ display: 0 > 0 ? "flex" : "none" }} />



            <SoftBox display={0 == 0 ? "flex" : "none"} justifyContent="space-between" alignItems="flex-start" flexDirection="column" gap={1} px={1} pt={2}>

              <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center" >
                {/* <SoftTypography variant="button" fontWeight="medium" ml={0.5} mr={1.5}>
                                Flavor
                            </SoftTypography> */}

                <SoftAvatar
                  key={"memberKey"}
                  icon="local_dining"
                  bgColor="flatwhite"
                  variant="rounded"
                  size="md"
                  sx={({ borders: { borderWidth }, palette: { white } }) => ({
                    border: `${borderWidth[2]} solid ${white.main}`,
                    cursor: "pointer",
                    position: "relative",
                  })}
                  style={{ marginRight: "4px", marginTop: "-2px", fontSize: "20px" }}

                />

                <SoftTypography variant="button" fontWeight="medium" color="secondary">
                  <span style={{ color: "rgb(101 108 121)" }}>{foodCount}   </span>  Flavor  Rating
                </SoftTypography>
              </SoftBox>






              {0 > 0 ? <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center" >
                {/* <SoftTypography variant="button" fontWeight="medium" ml={0.5} mr={1.5}>
                                Route
                            </SoftTypography> */}

                <SoftAvatar
                  key={"memberKey"}
                  icon="route"
                  bgColor="flatwhite"
                  variant="rounded"
                  size="md"
                  sx={({ borders: { borderWidth }, palette: { white } }) => ({
                    border: `${borderWidth[2]} solid ${white.main}`,
                    cursor: "pointer",
                    position: "relative",

                  })}
                  style={{ marginRight: "4px", marginTop: "-2px", fontSize: "20px" }}

                />

                <SoftTypography variant="button" fontWeight="medium" color="secondary">
                  <span style={{ color: "rgb(101 108 121)" }}>{"0"}  </span>  Route Contains
                </SoftTypography>
              </SoftBox> : ""}


            </SoftBox> </> : ""}

        </SoftBox>

      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexProjectCard;

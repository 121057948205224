// StoryCard.js
import React from 'react';
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

/* eslint-disable react/prop-types */

import Stories from 'stories-react';
import 'examples/StoryCard/index.css';

function StoryCard({ image, name, detail, style, children, ...rest }) {

  const stories = [
    {
      type: 'image',
      duration: 6000,
      url: image,
    },
    {
      type: 'video',
      url: 'https://assets.mixkit.co/videos/preview/mixkit-man-dancing-under-changing-lights-1240-large.mp4',
      duration: 28000,
    },
    {
      type: 'image',
      duration: 6000,
      url: 'https://images.pexels.com/photos/9733197/pexels-photo-9733197.jpeg?w=300',
    },
    {
      type: 'video',
      url: 'https://assets.mixkit.co/videos/preview/mixkit-mother-with-her-little-daughter-eating-a-marshmallow-in-nature-39764-large.mp4',
      duration: 10000,
    },
    {
      type: 'image',
      url: image,
      duration: 10000,
    },

    {
      type: 'video',
      duration: 6000,
      url: 'https://assets.mixkit.co/videos/preview/mixkit-girl-in-neon-sign-1232-large.mp4',
    },
    {
      duration: 7000,
      type: 'image',
      url: 'https://images.pexels.com/photos/9470805/pexels-photo-9470805.jpeg?w=300',
    },
  ];

  return (
    <div
      {...rest}
      // Ana koddaki "style" prop'unu buradaki style ile birleştiriyoruz:
      style={{

        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '0% 0% / cover rgb(38 38 38)',
        //background: '0% 0% / cover rgb(89 93 99)',
        borderRadius: '10px',
        boxShadow: '0px 10px 30px rgba(0,0,0,0.1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '24px',
        //userSelect: 'none',
        
        backgroundSize: "cover",
        //pointerEvents: 'none',
        backgroundImage: `url(${image})`,
        background: `url(${image})`,
        ...style, // <- dışarıdan gelen ek stiller
      }}
    >
      <Stories width="340px" height="605px" stories={stories} />
       <div className="suggestion-card-overlay" style={{width: "-webkit-fill-available",    padding: "30px", marginTop:"460px", position:"absolute",pointerEvents:"none"}}>
       
        <p style={{fontSize:"18px", height:"auto", WebkitLineClamp:"none"}}>
          {name}
          <div style={{ display: "flex", flexDirection: "row", height: "12px" }}>
            <SoftAvatar
            icon="star"
            iconSize="small"
            iconColor="rgb(239 130 34)"
              style={{
                width: "20px",
                height: "20px",
                color: "rgb(239 130 34)",
                marginLeft: "-5px",
              }}
              sx={{ color: "rgb(239 130 34)" }}
            />
            <p style={{ fontSize: "12px", fontWeight: "lighter", color: "#ffffffc9" }}>
              {detail ? detail : "No detail"}
            </p>
          </div>
          <p style={{ textAlign: "end", marginRight: "-5px", marginTop: "-3px", fontSize: "13px" }}>
            {"︾"}
            
          </p>
        </p>
        
        
      </div>
      

      {children && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            backgroundColor: "#00000055",
            borderRadius: "4px",
            padding: "4px 8px",
            color: "#fff",
          }}
        >
          {children}
        </div>
      )} 
    </div>
  );
}

export default StoryCard;

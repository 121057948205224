import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import ProfilesList from 'examples/Lists/ProfilesList';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";


import Stories from "examples/Stories/PhotoStories";
import EatlistCards from "examples/EatlistCards";
import RouteCards from "examples/RouteCards";
import PlaceCards from "examples/PlaceCards";
import RatingCards from "examples/RatingRestaurantCards";
import { useTranslation } from 'react-i18next';
import Search from "examples/SearchBars"
import BasicStatisticsCard from "examples/Cards/StatisticsCards/BasicStatisticsCard";

import { useState } from "react";
import { f } from 'dropzone';

/* eslint-disable react/prop-types */

const Index = (data) => {

  const { t } = useTranslation();

  const SectionTitle = ({ title, style }) => (
    <h3 className="section-title" style={{ marginLeft: "60px" }}>{title}</h3>
  );


  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    // Arama işlemini burada gerçekleştirin
    console.log('Searching for:', searchTerm);
  };

  return (



    <SoftBox position="relative">


      {/* <SectionTitle title={t("all.ratings")} /> */}

      <div style={{ justifyItems: "center" }}>
      <br/>
        <Search
          searchTerm={searchTerm}
          handleChange={handleChange}
          handleSearch={handleSearch}

        />
        <br/>
        <Grid container spacing={{ xs: 0, sm: 3 }} mt={0} style={{justifyContent:"center"}}  >

          <Grid item xs={4} md={3}>
            <BasicStatisticsCard
              icon="filter_alt"
              count={{ number: "Filter", label: "" }}
              percentage={0 == 0 ? "‎" : "2♥"}
              dropdown={{
                action: "openClickEventsMenu",
                menu: "",
              }}
              link={data?.restaurant_tags?.["3"] > 0 ? `/highlight/${id}/favorite` : ""}
              color={data?.restaurant_tags?.["3"] > 0 ? "primary" : "dark"}

            />

          </Grid>
          <Grid item xs={4} md={3}>
            <BasicStatisticsCard
              icon="filter_list"
              iconSize = "large"
              count={{ number: "Sort", label: "" }}
              percentage={0 == 0 ? "‎" : "2♥"}
              dropdown={{
                action: "openClickEventsMenu",
                menu: "",
              }}
              link={data?.restaurant_tags?.["3"] > 0 ? `/highlight/${id}/favorite` : ""}
              color={data?.restaurant_tags?.["3"] > 0 ? "primary" : "dark"}

            />

          </Grid>


          <Grid item xs={4} md={3}>
            <BasicStatisticsCard
              icon="settings"
              iconSize = "large"
              count={{ number: "Settings", label: "" }}
              percentage={0 == 0 ? "‎" : "2♥"}
              dropdown={{
                action: "openClickEventsMenu",
                menu: "",
              }}
              link={data?.restaurant_tags?.["3"] > 0 ? `/highlight/${id}/favorite` : ""}
              color={data?.restaurant_tags?.["3"] > 0 ? "primary" : "dark"}
              sx={{fontSize: "30px !important"}}
            />

          </Grid>

        </Grid>
      </div>
      <RatingCards placeData={data?.data?.foodie?.[0]?.ratings} maxCount={data?.data?.foodie?.[0]?.ratings.length} />


    </SoftBox>





  )
}

export default Index



import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useState } from "react";

/* eslint-disable react/prop-types */
import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import TeamProfileCard from "examples/Cards/TeamCards/TeamProfileCard";
import BasicStatisticsCard from "examples/Cards/StatisticsCards/BasicStatisticsCard";
import EventCard from "examples/Cards/EventCard";

// Teams page components
import Stories from "examples/Stories/PhotoStories";
import Post from "examples/Posts";
import EatPost from "examples/EatPosts/detailed";
import Map from 'examples/Maps';

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/logos/food-icon-white.png";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoInvision from "assets/images/small-logos/logo-invision.svg";


function Page(props) {


    const { id, foodie } = useParams();
    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/review/${foodie}/${id}`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                console.log(error);

            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        // Sayfa açıldığında hedef bölüme kaydır
        const targetElement = document.getElementById("rating");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }, [data]);


    return (
        <>


            

            

            
            <Grid item xs={12}>
                <SoftBox mb={3}>
                    <Grid container spacing={3} justifyContent={"center"}>
                        <Grid container spacing={3} item xs={12} lg={8}>
                            
                                <Grid id="rating" item xs={12} >
                                    <EatPost id="rating" data={data} type={"place"} />

                                </Grid>

                        </Grid>

                    
                    </Grid>
                </SoftBox>
            </Grid>
        </>
    );
}

export default Page;
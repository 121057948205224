// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

/* eslint-disable react/prop-types */

function MiniStatisticsCard({ bgColor, title, count, percentage, icon, direction, image }) {
  return (
    <Card>
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2} pl={2}>
          <Grid container alignItems="center">
            {direction === "left" ? (
              <Grid item>
                <SoftAvatar
                  //variant="gradient"
                  bgColor={bgColor === "flatwhite" ? icon.color : "flatwhite"}
                  color={bgColor === "white" ? "flatwhite" : "flatwhite"}
                  width="3rem"
                  height="3rem"
                  borderRadius="none"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                  src={image ? window.BASE_URL +"/" +image: ""}
                  icon={icon.component}
                  circular={false}
                  variant="rounded"
                  //size="xxl"
                >
    
                </SoftAvatar>
              </Grid>
            ) : null}
            <Grid item xs={8}>
              <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
              <SoftTypography
                  variant="caption"
                  fontWeight="regular"
                  color={"secondary"}
                >
                  {"Eatlist"}{" "}
                  
                </SoftTypography>
              <SoftTypography
                  variant="h6"
                  fontWeight="medium"
                  color={bgColor === "white" ? "dark" : "white"}
                  sx={{marginBottom:"-5px"}}
                >
                  {count}{" "}
                  
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  //textTransform="capitalize"
                  //fontWeight={title.fontWeight}
                  fontWeight="regular"
                  
                >
                  <Icon style={{marginBottom:"-2px",marginRight:"3px"}}>person</Icon>
                  {""+title.text}
                </SoftTypography>

              </SoftBox>
            </Grid>
            {direction === "right" ? (
              <Grid item xs={4}>
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  marginLeft="auto"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null}
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {

  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark","flatwhite"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;

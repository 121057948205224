import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import SoftBox from "components/SoftBox";
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";

function SoftDropzone({ options, allowMultiple = true }) {
  const dropzoneRef = useRef();

  useEffect(() => {
    Dropzone.autoDiscover = false;

    const finalOptions = {
      ...options,
      uploadMultiple: allowMultiple, // Tekli veya çoklu yükleme kontrolü
      maxFiles: allowMultiple ? undefined : 1, // Tekli yüklemede en fazla 1 dosya
    };

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, finalOptions);
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, [options, allowMultiple]);

  return (
    <SoftDropzoneRoot
      component="form"
      action="/file-upload"
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <SoftBox className="fallback">
        <SoftBox component="input" name="file" type="file" multiple={allowMultiple} />
      </SoftBox>
    </SoftDropzoneRoot>
  );
}

SoftDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  allowMultiple: PropTypes.bool, // Eklenen prop
};

export default SoftDropzone;

import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BasicStatisticsCard from "examples/Cards/StatisticsCards/BasicStatisticsCard";

import ProfileCardSpecial from "examples/ProfileCards/ProfileCardSpecial";
import ProfileCardSpecialEatlist from "examples/ProfileCards/ProfileCardSpecialEatlist";
import ProfileCardSpecialRating from "examples/ProfileCards/ProfileCardSpecialRating";
import ProfileCardSpecialFollower from "examples/ProfileCards/ProfileCardSpecialFollower";
// import ProfileCardKurum from "examples/ProfileCards/ProfileCardKurum";
// import ProfileCardKurumSema from "examples/ProfileCards/ProfileCardKurumSema";
// import ProfileCardEgitim from "examples/ProfileCards/ProfileCardEgitim";
// import ProfileCardIzinler from "examples/ProfileCards/ProfileCardIzinler";
// import ProfileCardTerfi from "examples/ProfileCards/ProfileCardTerfi";
// import ProfileCardZimmet from "examples/ProfileCards/ProfileCardZimmet";
// import ProfileCardZimmetBack from "examples/ProfileCards/ProfileCardZimmetBack";
// import ProfileCardNot from "examples/ProfileCards/ProfileCardNot";
// import ProfileCardDosya from "examples/ProfileCards/ProfileCardDosya";
// import ProfileCardDisiplin from "examples/ProfileCards/ProfileCardDisiplin";
// import ProfileCardDisiplinVerilen from "examples/ProfileCards/ProfileCardDisiplinVerilen";
// import ProfileCardPerformans from "examples/ProfileCards/ProfileCardPerformansInews";

import ProfileCardStarred1 from "examples/ProfileCards/ProfileCardStarred1";
import ProfileCardStarred2 from "examples/ProfileCards/ProfileCardStarred2";
import ProfileCardStarred3 from "examples/ProfileCards/ProfileCardStarred3";
import ProfileCardHyped from "examples/ProfileCards/ProfileCardHyped";

import Stories from "examples/Stories/PhotoStories";
import EatlistCards from "examples/EatlistCards";
import RouteCards from "examples/RouteCards";
import PlaceCards from "examples/PlaceCards";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";


// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Overview() {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const onTabChange = (index) => {
    setTabIndex(index);
  }
  const { id, view } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const username = useSelector(state => state.auth.username);

  if (id === ":id") {
    navigate("/foodie/"+username);
  }
  


  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/foodie/${id}?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);


  return (
    <DashboardLayout>
      <Header onChange={e => onTabChange(e)} data={data} isLoading={isLoading} />
      <SoftBox mt={5} mb={12}>

        {(tabIndex === 0 || tabIndex === 1000) &&    (
          <Grid container spacing={{ xs: 0, sm: 3 }}>
            {/* <Grid item xs={3} md={3}>

              <BasicStatisticsCard
                icon="star"
                count={{ number: "Starred", label: data?.restaurant_tags?.["3"] + " Special Place" }}
                percentage={0 == 0 ? "‎" : "2♥"}
                dropdown={{
                  action: "openClickEventsMenu",
                  menu: "",
                }}
                link={data?.restaurant_tags?.["3"] > 0 ? `/highlight/${id}/starred` : ""}
                color={data?.restaurant_tags?.["3"] > 0 ? "warning" : "light"}
              />


            </Grid> */}
            <Grid item xs={4} md={4}>
              <BasicStatisticsCard
                icon="favorite"
                count={{ number: t("highlight.favorite"), label: data?.restaurant_tags?.["3"] + " " + t("highlight.subtitle") }}
                percentage={0 == 0 ? "‎" : "2♥"}
                dropdown={{
                  action: "openClickEventsMenu",
                  menu: "",
                }}
                link={data?.restaurant_tags?.["3"] > 0 ? `/highlight/${id}/favorite`: ""}
                color={data?.restaurant_tags?.["3"] > 0 ? "primary" : "light"}
                isLoading={!data?.foodie}
              />

            </Grid>
            <Grid item xs={4} md={4}>
              <BasicStatisticsCard
                icon="place"
                count={{ number: t("highlight.stop"), label: data?.restaurant_tags?.["2"] + " " + t("highlight.subtitle") }}
                percentage={0 == 0 ? "‎" : "2♥"}
                dropdown={{
                  action: "openClickEventsMenu",
                  menu: "",
                }}
                link={data?.restaurant_tags?.["2"] > 0 ? `/highlight/${id}/stop`: ""}
                color={data?.restaurant_tags?.["2"] > 0 ? "info" : "light"}
                isLoading={!data?.foodie}
              />

            </Grid>

            <Grid item xs={4} md={4}>
              <BasicStatisticsCard
                icon="flag"
                count={{ number: t("highlight.discover"), label: data?.restaurant_tags?.["0"] + " " + t("highlight.subtitle") }}
                percentage={0 == 0 ? "‎" : "2♥"}
                dropdown={{
                  action: "openClickEventsMenu",
                  menu: "",
                }}
                link={data?.restaurant_tags?.["0"] > 0 ? `/highlight/${id}/discover`: ""}
                color={data?.restaurant_tags?.["0"] > 0 ? "green" : "light"}
                isLoading={!data?.foodie}
              />

            </Grid>



          </Grid>
        )}


        {tabIndex === 0 && (

          <ProfileCardSpecial data={data} />



        )}
        {tabIndex === 1 && (
          <>
            <ProfileCardSpecialEatlist data={data} />


          </>
        )}
        {tabIndex === 2 && (
          <>
            <ProfileCardSpecialRating data={data} />

          </>
        )}

        {tabIndex === 3 && (
          <>
            <ProfileCardSpecialFollower data={data?.foodie?.[0]?.follower_list} title="Followers" onChange={e => onTabChange(e)} /> 

          </>
        )}

        {tabIndex === 4 && (
          <>
            <ProfileCardSpecialFollower data={data?.foodie?.[0]?.following_list} title="Following" onChange={e => onTabChange(e)} />

          </>
        )}


        {tabIndex === 5 && (
          <>
            {/* <ProfileCardZimmetBack data={data} />
            <ProfileCardZimmet data={data} /> */}

          </>
        )}

        {tabIndex === 6 && (
          <>
            {/* <ProfileCardPerformans data={data} /> */}

          </>
        )}

        {tabIndex === 7 && (
          <>
            {/* <ProfileCardDisiplin data={data} />
            <ProfileCardDisiplinVerilen data={data} /> */}

          </>
        )}

        {tabIndex === 8 && (
          <>
            {/* <ProfileCardNot data={data} /> */}

          </>
        )}

        {tabIndex === 11 && (
          <>
            <ProfileCardStarred1 data={data} />

          </>
        )}
        {tabIndex === 12 && (
          <>
            <ProfileCardStarred2 data={data} />

          </>
        )}
        {tabIndex === 13 && (
          <>
            <ProfileCardStarred3 data={data} />

          </>
        )}
        {tabIndex === 14 && (
          <>
            <ProfileCardHyped data={data} />

          </>
        )}



      </SoftBox>





    </DashboardLayout>
  );
}

export default Overview;
